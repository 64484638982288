#root .CompanyIntro {
  padding-bottom: 360px;
}
#root .CompanyIntro .backround-section {
  margin: 50px 0px 90px 0px;
  width: 100%;
  height: 285px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, from(#000000), to(rgba(0, 0, 0, 0.26)));
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.26) 100%);
  background-size: cover;
}
#root .CompanyIntro .backround-section > .title-section {
  padding-top: 123px;
  width: 1030px;
  margin: 0 auto;
}
#root .CompanyIntro .backround-section > .title-section > .title {
  font-weight: 700;
  font-size: 18px;
  color: #1963e8;
  margin-bottom: 10px;
}
#root .CompanyIntro .backround-section > .title-section > .sub {
  font-weight: 400;
  font-size: 30px;
  line-height: 140%;
}
#root .CompanyIntro .backround-section > .title-section > .sub > span {
  font-weight: 700;
}
#root .CompanyIntro .img-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .CompanyIntro .img-section > img {
  width: 1030px;
}

@media screen and (min-width: 1440px) {
  #root .CompanyIntro .backround-section {
    background-image: -webkit-image-set(url("/public/assets/company/companyintro/pc/company-img.png") 1x, url("/public/assets/company/companyintro/pc/company-img@2x.png") 2x, url("/public/assets/company/companyintro/pc/company-img@3x.png") 3x);
    background-image: image-set("/public/assets/company/companyintro/pc/company-img.png" 1x, "/public/assets/company/companyintro/pc/company-img@2x.png" 2x, "/public/assets/company/companyintro/pc/company-img@3x.png" 3x);
  }
}
@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .CompanyIntro {
    padding-bottom: 202px;
  }
  #root .CompanyIntro .backround-section {
    background-image: -webkit-image-set(url("/public/assets/company/companyintro/tablet/company-img.png") 1x, url("/public/assets/company/companyintro/tablet/company-img@2x.png") 2x, url("/public/assets/company/companyintro/tablet/company-img@3x.png") 3x);
    background-image: image-set("/public/assets/company/companyintro/tablet/company-img.png" 1x, "/public/assets/company/companyintro/tablet/company-img@2x.png" 2x, "/public/assets/company/companyintro/tablet/company-img@3x.png" 3x);
  }
  #root .CompanyIntro .backround-section > .title-section {
    width: 688px;
  }
  #root .CompanyIntro .img-section > img {
    width: 688px;
  }
}
@media screen and (max-width: 767px) {
  #root .CompanyIntro .backround-section {
    margin-top: 81px;
    height: 207px;
    background-image: -webkit-image-set(url("/public/assets/company/companyintro/mb/company-img.png") 1x, url("/public/assets/company/companyintro/mb/company-img@2x.png") 2x, url("/public/assets/company/companyintro/mb/company-img@3x.png") 3x);
    background-image: image-set("/public/assets/company/companyintro/mb/company-img.png" 1x, "/public/assets/company/companyintro/mb/company-img@2x.png" 2x, "/public/assets/company/companyintro/mb/company-img@3x.png" 3x);
  }
  #root .CompanyIntro .backround-section > .title-section {
    padding-top: 64px;
    width: 328px;
    margin: 0 auto;
    text-align: center;
  }
  #root .CompanyIntro .backround-section > .title-section > .sub {
    font-size: 26px;
  }
  #root .CompanyIntro .backround-section > .title-eng {
    padding-top: 0px;
  }
}