#root .input-section {
  position: relative;
}
#root .input-section .title {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  color: white;
}
#root .input-section > textarea {
  padding: 10px;
  width: 494px;
  height: 146px;
  border: 1px solid #949494;
  color: white;
  background-color: transparent;
}
#root .input-section > input {
  width: 494px;
  height: 50px;
  color: white;
  border: 1px solid #949494;
  background-color: transparent;
}

@media screen and (max-width: 767px) {
  #root .input-section {
    position: relative;
  }
  #root .input-section .title {
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }
  #root .input-section > textarea {
    padding: 10px;
    width: 320px;
    height: 146px;
    border: 1px solid #949494;
    color: white;
    background-color: transparent;
  }
  #root .input-section > input {
    width: 320px;
    height: 50px;
    color: white;
    border: 1px solid #949494;
    background-color: transparent;
  }
}