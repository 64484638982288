#root .BusinessService {
  padding-top: 150px;
}
#root .BusinessService > .main-section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 345px;
}
#root .BusinessService > .main-section > .title-section {
  margin-bottom: 70px;
  text-align: center;
  font-weight: 700;
  font-size: 34px;
}
#root .BusinessService > .main-section > .title-section > span {
  color: var(--blue600);
}
#root .BusinessService > .main-section > .content-section {
  display: grid;
  grid-template-columns: repeat(2, 352px);
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
  row-gap: 70px;
}
#root .BusinessService > .main-section > .content-section > .box {
  position: relative;
  padding: 24px;
  width: 352px;
  height: 352px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.2)), to(rgba(243, 243, 243, 0)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(243, 243, 243, 0) 100%);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px white;
          box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px white;
}
#root .BusinessService > .main-section > .content-section > .box > .title {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 24px;
}
#root .BusinessService > .main-section > .content-section > .box > .sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 4px;
  line-height: 150%;
  font-weight: 400;
  font-size: 14px;
}
#root .BusinessService > .main-section > .content-section > .box > .sub .dot {
  display: inline-block;
  margin-top: 10px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
}
#root .BusinessService > .main-section > .content-section > .box > .sub .div {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#root .BusinessService > .main-section > .content-section > .box > .img {
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: 132px;
}

@media screen and (max-width: 1439px) {
  #root .BusinessService {
    padding-top: 121px;
  }
  #root .BusinessService > .main-section {
    padding-bottom: 245px;
  }
  #root .BusinessService > .main-section > .content-section {
    display: grid;
    grid-template-columns: repeat(1, 352px);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    row-gap: 30px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  #root .BusinessService {
    padding-top: 181px;
  }
  #root .BusinessService > .main-section > .title-section {
    margin-bottom: 60px;
    font-size: 26px;
  }
  #root .BusinessService > .main-section > .content-section {
    grid-template-columns: repeat(1, 328px);
    row-gap: 50px;
  }
  #root .BusinessService > .main-section > .content-section > .box {
    width: 328px;
    height: 328px;
  }
  #root .BusinessService > .main-section > .content-section > .box > .title {
    margin-bottom: 10px;
    font-size: 20px;
  }
  #root .BusinessService > .main-section > .content-section > .box > .sub {
    font-size: 13px;
  }
}