#root .RecrultmentDetail {
  padding-bottom: 330px;
  padding-top: 257px;
}
#root .RecrultmentDetail .mb {
  display: none;
}
#root .RecrultmentDetail .title-section {
  width: 1030px;
  margin: 0 auto;
  padding-bottom: 62px;
}
#root .RecrultmentDetail .title-section .left .title {
  font-weight: 700;
  font-size: 60px;
}
#root .RecrultmentDetail .title-section .left .subtitle {
  font-weight: 700;
  font-size: 24px;
}
#root .RecrultmentDetail .background-section {
  position: relative;
  width: 100%;
  height: 576px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-size: cover;
}
#root .RecrultmentDetail .background-section > .text {
  font-weight: 400;
  font-size: 34px;
  line-height: 130%;
  text-align: center;
  margin-top: 35px;
}
#root .RecrultmentDetail .background-section > .text > span {
  font-weight: bold;
}
#root .RecrultmentDetail .background-section .line {
  bottom: -30px;
  position: absolute;
  width: 309px;
  background-color: white;
  height: 2px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
#root .RecrultmentDetail .section1 {
  width: 1172px;
  margin: 0 auto;
  margin-top: 251px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 160px;
  height: 1625px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
#root .RecrultmentDetail .section1 .tag-wrapper {
  z-index: 10;
  margin-bottom: 433px;
  -webkit-column-gap: 73px;
     -moz-column-gap: 73px;
          column-gap: 73px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 560px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
  row-gap: 15px;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag {
  padding: 12px 40px;
  width: 100%;
  height: 60px;
  background-color: white;
  border-radius: 105px;
  color: #1963e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 700;
  font-size: 22px;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag1 {
  width: 340px;
  background: #71a0f7;
  color: black;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag2 {
  width: 205px;
  background: #1963e8;
  color: white;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag3 {
  width: 289px;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag4 {
  width: 254px;
  background: #71a0f7;
  color: black;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag5 {
  width: 278px;
  background: #1963e8;
  color: white;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag6 {
  width: 265px;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag7 {
  width: 165px;
}
#root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag8 {
  width: 381px;
  background: #1963e8;
  color: white;
}
#root .RecrultmentDetail .section1 .tag-wrapper .text-section {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
}
#root .RecrultmentDetail .section1 .tag-wrapper .text-section > span {
  font-weight: 700;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section {
  margin-bottom: 51px;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section > .title {
  font-weight: 700;
  font-size: 34px;
  color: #1963e8;
  margin-bottom: 18px;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section > .sub {
  font-weight: 700;
  font-size: 20px;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .content-section {
  margin-bottom: 25px;
  -webkit-column-gap: 148px;
     -moz-column-gap: 148px;
          column-gap: 148px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 935px;
  height: 230px;
  background-color: var(--gray500);
  border-radius: 220px;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .content-section .box {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: var(--gray600);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .content-section .box .img {
  width: 100px;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 100px;
     -moz-column-gap: 100px;
          column-gap: 100px;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .box {
  width: 256px;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .box > .text {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}
#root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .box > .sub {
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
}
#root .RecrultmentDetail .section1 .igis {
  top: 288px;
  left: -200px;
  position: absolute;
}
#root .RecrultmentDetail .section1 .igis > img {
  width: 744px;
}
#root .RecrultmentDetail .section2 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 1435px;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(1.65%, rgba(255, 255, 255, 0)), color-stop(85.84%, rgba(25, 99, 232, 0.97)));
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 1.65%, rgba(25, 99, 232, 0.97) 85.84%);
}
#root .RecrultmentDetail .section2 > .transparent {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
#root .RecrultmentDetail .section2 > .title {
  font-weight: 700;
  font-size: 34px;
  color: white;
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 51px;
}
#root .RecrultmentDetail .section2 .section2-content {
  display: grid;
  grid-template-columns: repeat(2, 421px);
  gap: 20px;
  position: relative;
  z-index: 1;
}
#root .RecrultmentDetail .section2 .section2-content > .box {
  width: 421px;
  height: 140px;
  background-color: white;
  border-radius: 10px;
  padding: 20px 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .RecrultmentDetail .section2 .section2-content > .box .text-wrapper > .text {
  font-weight: 700;
  font-size: 20px;
  color: #1963e8;
  line-height: 150%;
  margin-bottom: 10px;
}
#root .RecrultmentDetail .section2 .section2-content > .box .text-wrapper > .sub {
  font-weight: 700;
  font-size: 14px;
  color: black;
}
#root .RecrultmentDetail .section3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 4387px;
  position: relative;
  width: 992px;
  margin: 0 auto;
}
#root .RecrultmentDetail .section3 .back-icon > img {
  position: absolute;
  width: 755px;
}
#root .RecrultmentDetail .section3 .left > img {
  top: -250px;
  left: -400px;
}
#root .RecrultmentDetail .section3 .right > img {
  top: 1000px;
  right: -400px;
}
#root .RecrultmentDetail .section3 > .title {
  font-weight: 700;
  font-size: 34px;
  color: #1963e8;
  margin-bottom: 54px;
}
#root .RecrultmentDetail .section3 .section3-content {
  width: 880px;
  z-index: 10;
  margin-bottom: 335px;
}
#root .RecrultmentDetail .section3 .section3-content .top {
  width: 880px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
#root .RecrultmentDetail .section3 .section3-content .top .left,
#root .RecrultmentDetail .section3 .section3-content .top .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
}
#root .RecrultmentDetail .section3 .section3-content .bottom {
  margin-top: 20px;
}
#root .RecrultmentDetail .section3 .section3-content .item1 {
  width: 528px;
  height: 334px;
}
#root .RecrultmentDetail .section3 .section3-content .item2 {
  width: 332px;
  height: 210px;
}
#root .RecrultmentDetail .section3 .section3-content .item3 {
  width: 528px;
  height: 282px;
}
#root .RecrultmentDetail .section3 .section3-content .item4 {
  width: 332px;
  height: 407px;
}
#root .RecrultmentDetail .section3 .section3-content .item5 {
  width: 329px;
  height: 207px;
}
#root .RecrultmentDetail .section3 .section3-content .item6 {
  width: 531px;
  height: 334px;
}
#root .RecrultmentDetail .section3 .section3-content .item7 {
  width: 329px;
  height: 366px;
}
#root .RecrultmentDetail .section3 .section3-content .item8 {
  width: 531px;
  height: 240px;
}
#root .RecrultmentDetail .section3 .section3-1 > .title-wrapper {
  margin-bottom: 74px;
  text-align: center;
}
#root .RecrultmentDetail .section3 .section3-1 > .title-wrapper > .title {
  font-weight: 700;
  font-size: 34px;
  color: #1963e8;
  line-height: 130%;
  margin-bottom: 15px;
}
#root .RecrultmentDetail .section3 .section3-1 > .title-wrapper > .sub {
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
}
#root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .top {
  margin-bottom: 23px;
  padding: 50px;
  width: 992px;
  min-height: 232px;
  background-color: var(--gray600);
  border-radius: 10px;
}
#root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .top > .title {
  font-weight: 700;
  font-size: 30px;
  color: #1963e8;
  margin-bottom: 8px;
}
#root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .top > .sub {
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  white-space: pre-line;
}
#root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom {
  width: 992px;
  height: 491px;
  background-color: var(--gray600);
  border-radius: 10px;
}
#root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom .order {
  margin-top: 61px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 33px;
     -moz-column-gap: 33px;
          column-gap: 33px;
}
#root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom .order > .box > .img {
  width: 146px;
  height: 146px;
  border-radius: 50%;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom .order > .box > .img > img {
  width: 100px;
}
#root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom .order > .box > .text {
  text-align: center;
  margin-top: 14px;
  font-weight: 700;
  font-size: 14px;
}
#root .RecrultmentDetail .section3 .section3-2 {
  width: 992px;
  margin: 0 auto;
}
#root .RecrultmentDetail .section3 .section3-2 > .title {
  margin-bottom: 50px;
  width: 100%;
  font-weight: 700;
  font-size: 34px;
  color: #1963e8;
}
#root .RecrultmentDetail .section3 .section3-2 > .content {
  width: 992px;
}
#root .RecrultmentDetail .section3 .section3-2 > .content > .top {
  font-weight: 700;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px;
  width: 100%;
  height: 72px;
  background-color: #1963e8;
  border-radius: 10px 10px 0px 0px;
}
#root .RecrultmentDetail .section3 .section3-2 > .content > .bottom {
  padding: 57px 69px;
  width: 100%;
  background-color: var(--gray600);
}
#root .RecrultmentDetail .section3 .section3-2 > .content > .bottom .text-section > .box {
  margin-bottom: 35px;
}
#root .RecrultmentDetail .section3 .section3-2 > .content > .bottom .text-section > .box > .title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  font-weight: 700;
  font-size: 16px;
  color: #1963e8;
}
#root .RecrultmentDetail .section3 .section3-2 > .content > .bottom .text-section > .box > .sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 223%;
  white-space: pre-line;
}
#root .RecrultmentDetail .section3 .section3-btn {
  margin-bottom: 337px;
  width: 320px;
  height: 50px;
  background-color: #1963e8;
  font-weight: 700;
  font-size: 16px;
  margin-top: 39px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (min-width: 1440px) {
  #root .RecrultmentDetail .background-section {
    background-image: -webkit-image-set(url("/public/assets/recrultment/detail/pc/background-img.png") 1x, url("/public/assets/recrultment/detail/pc/background-img@2x.png") 2x, url("/public/assets/recrultment/detail/pc/background-img@3x.png") 3x);
    background-image: image-set("/public/assets/recrultment/detail/pc/background-img.png" 1x, "/public/assets/recrultment/detail/pc/background-img@2x.png" 2x, "/public/assets/recrultment/detail/pc/background-img@3x.png" 3x);
  }
}
@media screen and (max-width: 1439px) {
  #root .RecrultmentDetail {
    padding-top: 217px;
  }
  #root .RecrultmentDetail .background-section {
    background-image: -webkit-image-set(url("/public/assets/recrultment/detail/tablet/background-img.png") 1x, url("/public/assets/recrultment/detail/tablet/background-img@2x.png") 2x, url("/public/assets/recrultment/detail/tablet/background-img@3x.png") 3x);
    background-image: image-set("/public/assets/recrultment/detail/tablet/background-img.png" 1x, "/public/assets/recrultment/detail/tablet/background-img@2x.png" 2x, "/public/assets/recrultment/detail/tablet/background-img@3x.png" 3x);
  }
  #root .RecrultmentDetail > .title-section {
    padding-bottom: 73px;
    width: 688px;
  }
  #root .RecrultmentDetail > .title-section .left .title {
    font-size: 50px;
  }
  #root .RecrultmentDetail .section1 {
    height: 2600px;
    width: unset;
    margin-top: 400px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper {
    margin-bottom: 348px;
    display: block;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section {
    margin-bottom: 84px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section {
    margin-bottom: 94px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section > .title {
    font-weight: 700;
    font-size: 34px;
    color: #1963e8;
    margin-bottom: 18px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section > .sub {
    font-weight: 700;
    font-size: 20px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-section {
    display: grid;
    grid-template-columns: repeat(1);
    width: 330px;
    height: 1156px;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    padding: 40px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .box {
    position: absolute;
    width: 256px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .first {
    top: 1520px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .second {
    top: 1880px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .third {
    top: 2240px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  #root .RecrultmentDetail .section1 .igis {
    top: 160px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  #root .RecrultmentDetail .section1 .igis > img {
    width: 448px;
  }
  #root .RecrultmentDetail .section2 {
    height: 2020px;
  }
  #root .RecrultmentDetail .section2 .section2-content {
    grid-template-columns: repeat(1, 421px);
  }
  #root .RecrultmentDetail .section3 {
    width: 688px;
  }
  #root .RecrultmentDetail .section3 .back-icon > img {
    width: 445px;
  }
  #root .RecrultmentDetail .section3 .left > img {
    top: -150px;
    left: -250px;
  }
  #root .RecrultmentDetail .section3 .right > img {
    top: 1100px;
    right: -250px;
  }
  #root .RecrultmentDetail .section3 .section3-content {
    width: 688px;
    z-index: 10;
    margin-bottom: 335px;
  }
  #root .RecrultmentDetail .section3 .section3-content .top {
    width: 688px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
  }
  #root .RecrultmentDetail .section3 .section3-content .top .left,
  #root .RecrultmentDetail .section3 .section3-content .top .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 15px;
  }
  #root .RecrultmentDetail .section3 .section3-content .bottom {
    margin-top: 15px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item1 {
    width: 412px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item2 {
    width: 259px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item3 {
    width: 412px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item4 {
    width: 259px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item5 {
    width: 257px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item6 {
    width: 415px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item7 {
    width: 257px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item8 {
    width: 415px;
  }
  #root .RecrultmentDetail .section3 .section3-1 > .title-wrapper {
    margin-bottom: 74px;
    text-align: center;
  }
  #root .RecrultmentDetail .section3 .section3-1 > .title-wrapper > .title {
    font-weight: 700;
    font-size: 34px;
    color: #1963e8;
    line-height: 130%;
    margin-bottom: 15px;
  }
  #root .RecrultmentDetail .section3 .section3-1 > .title-wrapper > .sub {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .top {
    width: 688px;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom {
    width: 688px;
    height: 693px;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom .order {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    row-gap: 40px;
  }
  #root .RecrultmentDetail .section3 .section3-2 {
    width: 688px;
  }
  #root .RecrultmentDetail .section3 .section3-2 > .content {
    width: 688px;
  }
}
@media screen and (max-width: 767px) {
  #root .RecrultmentDetail {
    padding-bottom: 0;
  }
  #root .RecrultmentDetail .mb {
    display: block;
  }
  #root .RecrultmentDetail .pc {
    display: none;
  }
  #root .RecrultmentDetail > .title-section {
    padding-bottom: 73px;
    width: 328px;
  }
  #root .RecrultmentDetail > .title-section .left .title {
    font-size: 31px;
  }
  #root .RecrultmentDetail .background-section {
    height: 384px;
    background-image: -webkit-image-set(url("/public/assets/recrultment/detail/mb/background-img.png") 1x, url("/public/assets/recrultment/detail/mb/background-img@2x.png") 2x, url("/public/assets/recrultment/detail/mb/background-img@3x.png") 3x);
    background-image: image-set("/public/assets/recrultment/detail/mb/background-img.png" 1x, "/public/assets/recrultment/detail/mb/background-img@2x.png" 2x, "/public/assets/recrultment/detail/mb/background-img@3x.png" 3x);
  }
  #root .RecrultmentDetail .background-section > .text {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    margin-top: 60px;
  }
  #root .RecrultmentDetail .background-section .line {
    width: 166px;
  }
  #root .RecrultmentDetail .section1 {
    margin-top: 146px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper {
    z-index: 10;
    width: 328px;
    margin: 0 auto;
    margin-bottom: 270px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section {
    width: 328px;
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    row-gap: 7px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag {
    padding: 12px 16px;
    width: 100%;
    height: 48px;
    font-size: 15px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag1 {
    width: 206px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag2 {
    width: 113px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag3 {
    width: 157px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag4 {
    width: 145px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag5 {
    width: 165px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag6 {
    width: 155px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag7 {
    width: 89px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .tag-section .tag8 {
    width: 206px;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .text-section {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .text-section > span {
    font-weight: 400;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .text-section > span > br {
    display: none;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .text-section > br {
    display: none;
  }
  #root .RecrultmentDetail .section1 .tag-wrapper .text-section > .mb {
    display: block;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section {
    margin-bottom: 63px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section > .title {
    font-size: 24px;
    margin-bottom: 15px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .title-section > .sub {
    font-weight: 700;
    font-size: 18px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-section {
    display: grid;
    grid-template-columns: repeat(1);
    width: 280px;
    height: 1030px;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    padding: 40px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .box {
    width: 225px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .box .text {
    font-weight: 700;
    font-size: 18px;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .box .sub {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .first {
    top: 1470px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .second {
    top: 1790px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  #root .RecrultmentDetail .section1 .section1-sub-wrapper .content-text > .third {
    top: 2110px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  #root .RecrultmentDetail .section2 {
    height: 1460px;
    margin-bottom: 153px;
  }
  #root .RecrultmentDetail .section2 > .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }
  #root .RecrultmentDetail .section2 .section2-content {
    grid-template-columns: repeat(2, 159px);
    -webkit-column-gap: 9px;
       -moz-column-gap: 9px;
            column-gap: 9px;
    row-gap: 22px;
  }
  #root .RecrultmentDetail .section2 .section2-content > .box {
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    position: relative;
    width: 159px;
    height: 206px;
    border-radius: 10px;
    padding: 15px 14px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .RecrultmentDetail .section2 .section2-content > .box .text-wrapper > .text {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 4px;
  }
  #root .RecrultmentDetail .section2 .section2-content > .box .text-wrapper > .sub {
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
  }
  #root .RecrultmentDetail .section2 .section2-content > .box > .img {
    top: 116px;
    right: 10px;
    position: absolute;
    width: 80px;
  }
  #root .RecrultmentDetail .section3 {
    height: unset;
    margin-bottom: 480px;
    width: unset;
  }
  #root .RecrultmentDetail .section3 .section3-content {
    width: 328px;
    margin-bottom: 193px;
  }
  #root .RecrultmentDetail .section3 .section3-content .top {
    width: 328px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 7px;
  }
  #root .RecrultmentDetail .section3 .section3-content .top .left,
  #root .RecrultmentDetail .section3 .section3-content .top .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 7px;
  }
  #root .RecrultmentDetail .section3 .section3-content .bottom {
    margin-top: 7px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item1 {
    width: 196px;
    height: 142px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item2 {
    width: 123.37px;
    height: 89.74px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item3 {
    width: 196.2px;
    height: 120.5px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item4 {
    width: 123.37px;
    height: 173.92px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item5 {
    width: 122.25px;
    height: 88.45px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item6 {
    width: 197.31px;
    height: 142.72px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item7 {
    width: 122.25px;
    height: 156.4px;
  }
  #root .RecrultmentDetail .section3 .section3-content .item8 {
    width: 197.31px;
    height: 102.56px;
  }
  #root .RecrultmentDetail .section3 .back-icon {
    display: none;
  }
  #root .RecrultmentDetail .section3 .section3-1 > .title-wrapper {
    margin-bottom: 37px;
  }
  #root .RecrultmentDetail .section3 .section3-1 > .title-wrapper > .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 12px;
  }
  #root .RecrultmentDetail .section3 .section3-1 > .title-wrapper > .sub {
    font-weight: 400;
    font-size: 13px;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .top {
    width: 328px;
    height: 198px;
    padding: 30px 17px;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .top > .title {
    font-size: 16px;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .top > .sub {
    font-size: 13px;
    line-height: 150%;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom {
    width: 328px;
    height: 759px;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom .order {
    display: grid;
    grid-template-columns: repeat(2, 130px);
    row-gap: 65px;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom .order > .box {
    width: 130px;
    height: 130px;
  }
  #root .RecrultmentDetail .section3 .section3-1 .section3-1-content > .bottom .order > .box > .img {
    width: 130px;
    height: 130px;
  }
  #root .RecrultmentDetail .section3 .section3-2 {
    width: 328px;
  }
  #root .RecrultmentDetail .section3 .section3-2 > .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 19px;
  }
  #root .RecrultmentDetail .section3 .section3-2 > .content {
    width: 328px;
  }
  #root .RecrultmentDetail .section3 .section3-2 > .content .top {
    height: 53px;
  }
  #root .RecrultmentDetail .section3 .section3-2 > .content .bottom {
    padding: 39px 22px;
  }
  #root .RecrultmentDetail .section3 .section3-2 > .content .bottom .text-section .box .sub {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 4px;
  }
}