#root .CompanyNews {
  padding-top: 179px;
}
#root .CompanyNews > .main-section {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 998px;
  margin: 0 auto;
  padding-bottom: 107px;
  font-size: 50px;
}
#root .CompanyNews > .main-section .news-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .CompanyNews > .main-section .news-wrapper .news {
  -webkit-box-sizing: unset;
          box-sizing: unset;
  height: 240px;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  width: 998px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
  padding: 69px 0;
  border-bottom: 1px solid var(--gray600);
}
#root .CompanyNews > .main-section .news-wrapper .news:last-child {
  border-bottom: unset;
}
#root .CompanyNews > .main-section .news-wrapper .news .left {
  width: 317px;
  min-height: 240px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#root .CompanyNews > .main-section .news-wrapper .news .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .CompanyNews > .main-section .news-wrapper .news .right > .top .title {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 5px;
}
#root .CompanyNews > .main-section .news-wrapper .news .right > .top .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
#root .CompanyNews > .main-section .news-wrapper .news .right .date {
  font-weight: 500;
  font-size: 14px;
  float: flex-end;
}

@media screen and (max-width: 1439px) {
  #root .CompanyNews > .main-section {
    width: 688px;
  }
  #root .CompanyNews > .main-section .news-wrapper .news {
    width: 688px;
    height: 240px;
  }
  #root .CompanyNews > .main-section .news-wrapper .news .left {
    width: 317px;
    height: 240px;
  }
}
@media screen and (max-width: 767px) {
  #root .CompanyNews {
    padding-top: 58px;
    padding-bottom: 225px;
  }
  #root .CompanyNews > .main-section {
    width: 328px;
  }
  #root .CompanyNews > .main-section .news-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 40px;
  }
  #root .CompanyNews > .main-section .news-wrapper .news {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 328px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 40px 0;
  }
  #root .CompanyNews > .main-section .news-wrapper .news .left {
    width: 328px;
    margin-bottom: 30px;
  }
  #root .CompanyNews > .main-section .news-wrapper .news .right {
    border: unset;
    width: 100%;
  }
  #root .CompanyNews > .main-section .news-wrapper .news .right > .top {
    margin-bottom: 43px;
  }
  #root .CompanyNews > .main-section .news-wrapper .news .right > .top .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
  #root .CompanyNews > .main-section .news-wrapper .news .right > .top .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
  }
  #root .CompanyNews > .main-section .news-wrapper .news .right:not(:last-child) {
    border-bottom: unset;
  }
}