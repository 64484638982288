#root .contact {
  padding-top: 140px;
}
#root .contact .wrapper {
  position: relative;
  width: 1105px;
}
#root .contact .wrapper > .left {
  margin-bottom: 53px;
}
#root .contact .wrapper > .left .title {
  font-weight: 700;
  font-size: 34px;
  line-height: 150%;
}
#root .contact .wrapper > .left > img {
  width: 155px;
}
#root .contact .wrapper .right .title-wrapper {
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  margin-bottom: 32px;
}
#root .contact .wrapper .right .right-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 40px;
}
#root .contact .wrapper .right .contact-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}
#root .contact .wrapper .right .agreement-text {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  background-color: #242627;
}
#root .contact .wrapper .right .agreement-btn {
  margin-top: 24px;
  margin-bottom: 54px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 494px;
  height: 50px;
  background-color: #242627;
  font-weight: bold;
}
#root .contact .wrapper .right .agreement-btn .left {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #949494;
}
#root .contact .wrapper .right .agreement-btn .left > img {
  width: 20px;
  height: 20px;
}
#root .contact .wrapper .right .agreement-btn .left-check {
  border: unset;
}
#root .contact .wrapper .right .check-btn {
  background-color: #f5f6f8;
  color: black;
}
#root .contact .wrapper .right .btn {
  width: 320px;
  height: 50px;
  background-color: #1963e8;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
}
#root .contact .wrapper .right .is-check-btn {
  background-color: #242627;
  cursor: unset;
  opacity: unset;
}
#root .contact .wrapper .right .btn-text {
  font-weight: 700;
  font-size: 14px;
  color: #1963e8;
}
#root .contact .wrapper .right-eng {
  font-size: 12px;
  margin-left: 10px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .contact {
    padding-top: 169px;
  }
  #root .contact .wrapper {
    width: 494px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .contact .wrapper > .left {
    text-align: center;
    margin-bottom: 36px;
  }
  #root .contact .wrapper > .left .etc {
    display: none;
  }
  #root .contact .wrapper .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .contact .wrapper .right .btn {
    width: 320px;
    height: 50px;
    background-color: #1963e8;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px;
  }
  #root .contact .wrapper .right .is-check-btn {
    background-color: #242627;
    cursor: unset;
    opacity: unset;
  }
  #root .contact .wrapper .right .btn-text {
    font-weight: 700;
    font-size: 14px;
    color: #1963e8;
  }
}
@media screen and (max-width: 767px) {
  #root .contact {
    padding-top: 150px;
  }
  #root .contact .wrapper {
    width: 320px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .contact .wrapper > .left {
    text-align: center;
    margin-bottom: 46px;
  }
  #root .contact .wrapper > .left > .title {
    font-size: 24px;
  }
  #root .contact .wrapper > .left .etc {
    display: none;
  }
  #root .contact .wrapper .right .agreement-text {
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    line-height: 180%;
    background-color: #242627;
  }
  #root .contact .wrapper .right .agreement-btn {
    width: 320px;
  }
  #root .contact .wrapper .right .btn {
    width: 320px;
  }
  #root .contact .wrapper .right .btn-text {
    text-align: center;
    font-size: 12px;
  }
  #root .contact .wrapper .right-eng {
    line-height: 150%;
  }
}