@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
#root .business-page {
  position: relative;
  padding-top: 257px;
}
#root .business-page > .title-section {
  padding-bottom: 73px;
  width: 1030px;
  position: relative;
  margin: 0 auto;
}
#root .business-page > .title-section .left {
  position: relative;
  z-index: 10;
  height: 100px;
}
#root .business-page > .title-section .left .title {
  font-weight: 700;
  font-size: 60px;
}
#root .business-page > .title-section .left .subtitle {
  font-weight: 700;
  font-size: 24px;
}
#root .business-page > .title-section > .right > .gif > video {
  width: 702px;
  position: absolute;
  top: -110px;
  right: -50px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .business-page {
    padding-top: 199px;
  }
  #root .business-page > .title-section {
    padding-bottom: 73px;
    width: 688px;
  }
  #root .business-page > .title-section .left .title {
    font-size: 50px;
  }
  #root .business-page > .title-section > .right > .gif > video {
    width: 418px;
    top: -50px;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  #root .business-page {
    padding-top: 255px;
  }
  #root .business-page > .title-section {
    padding-bottom: 73px;
    width: 328px;
  }
  #root .business-page > .title-section .left {
    height: 70px;
  }
  #root .business-page > .title-section .left .title {
    font-size: 31px;
  }
  #root .business-page > .title-section > .right > .gif > video {
    width: 360px;
    top: -170px;
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
}