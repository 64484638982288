#root .VideoDetail {
  padding-top: 124px;
  padding-bottom: 219px;
}
#root .VideoDetail .video-section {
  width: 1068px;
  margin: 0 auto;
}
#root .VideoDetail .video-section .video-detail .video-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray300);
  margin-bottom: 20px;
}
#root .VideoDetail .video-section .video-detail .video-title .title {
  font-weight: 700;
  font-size: 24px;
}
#root .VideoDetail .video-section .video-detail .video-title .date {
  font-weight: 400;
  font-size: 16px;
}
#root .VideoDetail .video-section .btn {
  padding-top: 79px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .VideoDetail .video-section .btn .list-btn {
  width: 312px;
  height: 47px;
  background-color: #1963e8;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
}

@media screen and (max-width: 1439px) {
  #root .VideoDetail {
    padding-top: 95px;
  }
  #root .VideoDetail .video-section {
    width: 688px;
  }
  #root .VideoDetail .video-section .video-detail .video-title {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  #root .VideoDetail {
    padding-top: 95px;
  }
  #root .VideoDetail .video-section {
    width: 328px;
  }
  #root .VideoDetail .video-section .video-detail .video-title {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .VideoDetail .video-section .video-detail .video-title .title {
    line-height: 150%;
    margin-bottom: 6px;
  }
}