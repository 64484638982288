#root .AwardProperty {
  padding-bottom: 288px;
}
#root .AwardProperty .main-section {
  width: 1048px;
  position: relative;
  text-align: center;
  padding-top: 229px;
  margin: 0 auto;
}
#root .AwardProperty .main-section > .gradation-section {
  top: 140px;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  width: 313px;
  height: 313px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 99, 232, 0.7)), to(rgba(0, 0, 0, 0.21)));
  background: linear-gradient(180deg, rgba(25, 99, 232, 0.7) 0%, rgba(0, 0, 0, 0.21) 100%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(44.5px);
          filter: blur(44.5px);
}
#root .AwardProperty .main-section > .title-section {
  position: relative;
}
#root .AwardProperty .main-section > .title-section > .title {
  font-weight: 700;
  font-size: 30px;
  line-height: 140%;
}
#root .AwardProperty .main-section > .img-section {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 224px);
  gap: 50px;
}
#root .AwardProperty .main-section > .img-section > .content {
  z-index: 1;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
}
#root .AwardProperty .main-section > .img-section > .content > .property-img {
  margin-bottom: 12px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .pc {
    display: none;
  }
  #root .mb {
    display: none;
  }
  #root .tablet {
    display: block;
  }
  #root .AwardProperty .main-section {
    width: 688px;
  }
  #root .AwardProperty .main-section > .img-section {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 147px);
    -webkit-column-gap: 33px;
       -moz-column-gap: 33px;
            column-gap: 33px;
    row-gap: 60px;
  }
  #root .AwardProperty .main-section > .img-section > .content {
    font-size: 11px;
  }
}
@media screen and (max-width: 767px) {
  #root .AwardProperty .mb {
    display: block;
  }
  #root .AwardProperty .pc {
    display: none;
  }
  #root .AwardProperty .tablet {
    display: none;
  }
  #root .AwardProperty .main-section {
    width: 328px;
  }
  #root .AwardProperty .main-section > .img-section {
    grid-template-columns: repeat(3, 104px);
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    row-gap: 72px;
  }
  #root .AwardProperty .main-section > .img-section > .content {
    font-weight: 700;
    font-size: 10px;
  }
  #root .AwardProperty .main-section > .title-section > .title {
    font-size: 25px;
  }
}