#root .contact-button > .title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
}
#root .contact-button > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .contact-button > .btn-wrapper .contact-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 160px;
  height: 50px;
  position: relative;
  font-size: 16px;
}
#root .contact-button > .btn-wrapper .contact-btn .select {
  margin: 0px 8px 0px 15px;
  width: 20px;
  height: 20px;
  border: 1px solid #949494;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  #root .contact-button > .btn-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 8px;
  }
  #root .contact-button > .btn-wrapper .contact-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 50px;
    position: relative;
    font-size: 16px;
  }
}