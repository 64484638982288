#root .Recrultment {
  padding-bottom: 330px;
  padding-top: 257px;
}
#root .Recrultment .mb {
  display: none;
}
#root .Recrultment > .title-section {
  position: relative;
  width: 1030px;
  margin: 0 auto;
}
#root .Recrultment > .title-section .left {
  position: relative;
  z-index: 10;
}
#root .Recrultment > .title-section .left .title {
  font-weight: 700;
  font-size: 60px;
}
#root .Recrultment > .title-section .left .subtitle {
  font-weight: 700;
  font-size: 24px;
}
#root .Recrultment > .title-section > .right > .gif > video {
  width: 586px;
  position: absolute;
  top: -180px;
  right: 0;
}
#root .Recrultment > .line {
  width: 100%;
  height: 1px;
  background-color: var(--gray500);
  margin: 116px 0;
}
#root .Recrultment > .main-section {
  width: 1149px;
  margin: 0 auto;
}
#root .Recrultment > .main-section .title-wrapper {
  margin-bottom: 50px;
}
#root .Recrultment > .main-section .title-wrapper > .title {
  font-weight: 700;
  font-size: 18px;
  color: var(--blue600);
  margin-bottom: 12px;
}
#root .Recrultment > .main-section .title-wrapper > .sub {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
}
#root .Recrultment > .main-section .content-section {
  display: grid;
  grid-template-columns: repeat(3, 361px);
  -webkit-column-gap: 33px;
     -moz-column-gap: 33px;
          column-gap: 33px;
  row-gap: 80px;
}
#root .Recrultment > .main-section .content-section .box {
  width: 361px;
  height: 100%;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .Recrultment > .main-section .content-section .box .content {
  position: relative;
  padding: 20px 24px;
  margin-bottom: 15px;
  width: 361px;
  height: 269px;
  background-color: #1963e8;
}
#root .Recrultment > .main-section .content-section .box .content .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .Recrultment > .main-section .content-section .box .content .content-wrapper > .title {
  font-weight: 700;
  font-size: 30px;
}
#root .Recrultment > .main-section .content-section .box .content .content-wrapper .active {
  width: 54px;
  height: 26px;
  background-color: white;
  font-weight: 700;
  font-size: 13px;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .Recrultment > .main-section .content-section .box .content .img {
  right: 20px;
  position: absolute;
  margin-top: 15px;
  width: 135px;
  height: 135px;
  background-color: white;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .Recrultment > .main-section .content-section .box .content .img > img {
  width: 95px;
}
#root .Recrultment > .main-section .content-section .box .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 11px;
     -moz-column-gap: 11px;
          column-gap: 11px;
  margin-bottom: 5px;
}
#root .Recrultment > .main-section .content-section .box .text-wrapper .text {
  width: 60px;
  font-weight: 700;
  font-size: 14px;
  color: var(--gray300);
}
#root .Recrultment > .main-section .content-section .box .text-wrapper .subtext {
  width: 100%;
  font-weight: 400;
}
#root .Recrultment > .main-section .content-section > .dead-box .content {
  background-color: #191919;
}
#root .Recrultment > .main-section .content-section > .dead-box .content .content-wrapper > .title > .img {
  background-color: var(--gray500);
}
#root .Recrultment > .main-section .content-section > .dead-box .content .content-wrapper > .active {
  background-color: var(--gray500);
  color: white;
}
#root .Recrultment > .main-section .content-section .box:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .Recrultment {
    padding-bottom: 200px;
  }
  #root .Recrultment > .title-section {
    width: 688px;
  }
  #root .Recrultment > .title-section .left .title {
    font-size: 50px;
  }
  #root .Recrultment > .title-section > .right > .gif > video {
    width: 418px;
    height: 260px;
    top: -127px;
    right: 0;
  }
  #root .Recrultment > .line {
    margin: 65px 0;
  }
  #root .Recrultment > .main-section {
    width: 688px;
    margin: 0 auto;
  }
  #root .Recrultment > .main-section .content-section {
    grid-template-columns: repeat(2, 325px);
    -webkit-column-gap: 38px;
       -moz-column-gap: 38px;
            column-gap: 38px;
    row-gap: 58px;
  }
  #root .Recrultment > .main-section .content-section .box {
    width: 325px;
  }
  #root .Recrultment > .main-section .content-section .box .content {
    margin-bottom: 15px;
    width: 325px;
  }
}
@media screen and (max-width: 767px) {
  #root .Recrultment {
    padding-bottom: 110px;
    padding-top: 255px;
  }
  #root .Recrultment > .title-section {
    width: 328px;
  }
  #root .Recrultment > .title-section .left .title {
    font-size: 30px;
  }
  #root .Recrultment > .title-section .left .subtitle {
    font-size: 24px;
  }
  #root .Recrultment > .title-section > .right > .gif > video {
    width: 360px;
    height: 224px;
    top: -230px;
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  #root .Recrultment > .line {
    margin: 20px 0px 50px 0px;
  }
  #root .Recrultment > .main-section {
    width: 328px;
  }
  #root .Recrultment > .main-section .title-wrapper > .title {
    font-size: 18px;
  }
  #root .Recrultment > .main-section .title-wrapper > .sub {
    font-size: 24px;
  }
  #root .Recrultment > .main-section .content-section {
    grid-template-columns: repeat(1, 328px);
    -webkit-column-gap: 0px;
       -moz-column-gap: 0px;
            column-gap: 0px;
    row-gap: 40px;
  }
  #root .Recrultment > .main-section .content-section .box {
    width: 328px;
  }
  #root .Recrultment > .main-section .content-section .box .content {
    margin-bottom: 15px;
    width: 328px;
  }
}