#root .CompanyHistory {
  padding-bottom: 57px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .CompanyHistory .main-section {
  width: 100%;
  position: relative;
  text-align: center;
  padding-top: 229px;
}
#root .CompanyHistory .main-section > .gradation-section {
  top: 120px;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  width: 313px;
  height: 313px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(52, 72, 255, 0.5)), to(rgba(0, 0, 0, 0.15)));
  background: linear-gradient(180deg, rgba(52, 72, 255, 0.5) 0%, rgba(0, 0, 0, 0.15) 100%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(44.5px);
          filter: blur(44.5px);
}
#root .CompanyHistory .main-section > .title-section {
  position: relative;
}
#root .CompanyHistory .main-section > .title-section > .title {
  font-weight: 700;
  font-size: 20px;
  color: #1963e8;
  margin-bottom: 4px;
}
#root .CompanyHistory .main-section > .title-section > .sub {
  font-weight: 700;
  font-size: 34px;
}
#root .CompanyHistory .main-section .history-content-section {
  width: 1084px;
  margin: 0 auto;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), color-stop(81.25%, rgba(244, 245, 245, 0.140625)), to(rgba(0, 0, 0, 0.15)));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(244, 245, 245, 0.140625) 81.25%, rgba(0, 0, 0, 0.15) 100%);
  -webkit-box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  padding: 94px 134px;
}
#root .CompanyHistory .main-section .history-content-section .history-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
#root .CompanyHistory .main-section .history-content-section .history-box > .left-line {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .CompanyHistory .main-section .history-content-section .history-box > .left-line > .circle {
  position: absolute;
  top: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: var(--main);
}
#root .CompanyHistory .main-section .history-content-section .history-box > .left-line > .line {
  width: 2px;
  height: 100%;
  background-color: var(--main);
}
#root .CompanyHistory .main-section .history-content-section .history-box > .right-content {
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
  text-align: left;
  padding-bottom: 56px;
}
#root .CompanyHistory .main-section .history-content-section .history-box > .right-content > .year {
  font-weight: 700;
  font-size: 34px;
  color: var(--main);
}
#root .CompanyHistory .main-section .history-content-section .history-box > .right-content > .content {
  margin-top: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: white;
  white-space: pre-wrap;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .CompanyHistory .main-section .history-content-section {
    width: 760px;
  }
}
@media screen and (max-width: 767px) {
  #root .CompanyHistory .main-section .history-content-section {
    padding: 50px 20px;
    width: 100%;
  }
}