#root .solution {
  padding-bottom: 528px;
}
#root .solution .mb {
  display: none;
}
#root .solution .tablet {
  display: none;
}
#root .solution > .main-section {
  position: relative;
  width: 1120px;
  margin: 0 auto;
}
#root .solution > .main-section > .title-wrapper {
  margin-bottom: 165px;
  padding-top: 137px;
  width: 1300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .solution > .main-section > .title-wrapper > .title-section > .title {
  font-weight: 700;
  font-size: 34px;
  line-height: 130%;
  margin-bottom: 10px;
}
#root .solution > .main-section > .title-wrapper > .title-section > .sub {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}
#root .solution > .main-section > .title-wrapper > .img-section {
  z-index: 10;
}
#root .solution > .main-section > .title-wrapper > .img-section > img {
  width: 100%;
}
#root .solution > .main-section > .pilot-box-wrapper {
  margin-bottom: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .solution > .main-section > .pilot-box-wrapper > .title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 46px;
}
#root .solution > .main-section > .pilot-box-wrapper > .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 897px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
  row-gap: 20px;
}
#root .solution > .main-section > .pilot-box-wrapper > .box > .pilot-box {
  font-weight: 700;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 192px;
  height: 49px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(244, 245, 245, 0.140625)));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(244, 245, 245, 0.140625) 100%);
  -webkit-box-shadow: 0px 3.4477px 23.272px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 3.4477px 23.272px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(43.0962px);
          backdrop-filter: blur(43.0962px);
  border-radius: 8.61925px;
}
#root .solution > .main-section .IGISsystem {
  width: 1250px;
  padding-top: 0;
}
#root .solution > .main-section .IGISsystem > .title-section {
  padding-top: 195px;
}
#root .solution > .main-section .panorama > .title-section {
  padding-top: 155px;
}
#root .solution > .main-section .viewer {
  width: 1200px;
}
#root .solution > .main-section .viewer > .title-section {
  padding-top: 70px;
}
#root .solution > .main-section .control > .title-section {
  padding-top: 65px;
}
#root .solution > .main-section .streaming > .title-section {
  padding-top: 150px;
}
#root .solution > .main-section .pilot {
  width: 1200px;
  margin-bottom: 100px;
}
#root .solution > .main-section .pilot > .title-section {
  padding-top: 136px;
}
#root .solution > .main-section .ims {
  width: 1275px;
}
#root .solution > .main-section .FMS {
  width: 1300px;
  padding-top: 174px;
}
#root .solution > .main-section > .gradation {
  top: 70px;
  right: -100px;
  position: absolute;
  width: 651px;
  height: 285px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 128, 222, 0.6) 0%, rgba(0, 128, 222, 0) 100%);
}
#root .solution > .main-section .circle {
  width: 566px;
  height: 566px;
  right: -50px;
  top: 150px;
}
#root .solution > .main-section .viewercircle {
  width: 638px;
  height: 638px;
  right: -220px;
  top: 100px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 99, 232, 0.7)), to(rgba(0, 0, 0, 0.21)));
  background: linear-gradient(180deg, rgba(25, 99, 232, 0.7) 0%, rgba(0, 0, 0, 0.21) 100%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(44.5px);
          filter: blur(44.5px);
  -webkit-transform: rotate(-31.46deg);
          transform: rotate(-31.46deg);
  border-radius: 50%;
}
#root .solution > .main-section .controlcircle {
  width: 638px;
  height: 638px;
  right: -220px;
  top: 100px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 99, 232, 0.7)), to(rgba(0, 0, 0, 0.21)));
  background: linear-gradient(180deg, rgba(25, 99, 232, 0.7) 0%, rgba(0, 0, 0, 0.21) 100%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(44.5px);
          filter: blur(44.5px);
  -webkit-transform: rotate(-31.46deg);
          transform: rotate(-31.46deg);
  border-radius: 50%;
}
#root .solution > .main-section .pilotbasic {
  width: 826px;
  height: 361px;
  top: 170px;
  right: -60px;
}
#root .solution > .main-section > .content-section > .content {
  display: grid;
  grid-template-columns: repeat(2, 560px);
  border-top: 4px solid;
  -o-border-image: linear-gradient(260deg, rgba(255, 255, 255, 0) 0%, #115ee8 50.31%);
     border-image: linear-gradient(260deg, rgba(255, 255, 255, 0) 0%, #115ee8 50.31%);
  border-image-slice: 1;
}
#root .solution > .main-section > .content-section > .content > .box {
  padding: 50px;
  width: 560px;
  height: 521px;
  background-color: var(--gray600);
}
#root .solution > .main-section > .content-section > .content > .box .point {
  width: 95px;
  height: 30px;
  background-color: var(--blue600);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
}
#root .solution > .main-section > .content-section > .content > .box .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 20px;
}
#root .solution > .main-section > .content-section > .content > .box .sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 8px;
  line-height: 180%;
  font-weight: 400;
  font-size: 16px;
}
#root .solution > .main-section > .content-section > .content > .box .sub .dot {
  display: inline-block;
  margin-top: 13px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
}
#root .solution > .main-section > .content-section > .content > .box .sub .div {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#root .solution > .main-section > .content-section > .content > .box .subtitle-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
#root .solution > .main-section > .content-section > .content > .box .subtitle-wrapper .left {
  margin-top: 3px;
  margin-right: 48px;
  font-weight: 700;
  font-size: 20px;
}
#root .solution > .main-section > .content-section > .content > .box .eng-wrapper {
  display: block;
}
#root .solution > .main-section > .content-section > .content > .img-box {
  width: 560px;
  height: 521px;
}
#root .solution > .main-section > .content-section > .content > .img-box > img {
  width: 560px;
  height: 521px;
}
#root .solution > .main-section > .content-section > .reverse {
  grid-template-columns: repeat(2, 560px);
  direction: rtl;
}
#root .solution > .main-section > .content-section > .reverse > .box {
  direction: ltr;
}

@media screen and (max-width: 1439px) {
  #root .solution {
    padding-bottom: 290px;
  }
  #root .solution .pc {
    display: none;
  }
  #root .solution .tablet {
    display: block;
  }
  #root .solution > .main-section {
    width: 688px;
  }
  #root .solution > .main-section .title-wrapper {
    z-index: 10;
    position: relative;
  }
  #root .solution > .main-section .gradation {
    right: 0;
  }
  #root .solution > .main-section > .title-wrapper {
    text-align: center;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .solution > .main-section > .title-wrapper .title-section {
    margin-bottom: 90px;
  }
  #root .solution > .main-section > .pilot-box-wrapper > .box {
    width: 697px;
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
  }
  #root .solution > .main-section > .pilot-box-wrapper > .box > .pilot-box {
    width: 166px;
    height: 49px;
  }
  #root .solution > .main-section .IGISsystem {
    padding-top: 0;
  }
  #root .solution > .main-section .IGISsystem > .title-section {
    margin-bottom: 0;
    padding-top: 137px;
  }
  #root .solution > .main-section .FMS {
    padding-top: 137px;
  }
  #root .solution > .main-section .panorama > .title-section {
    padding-top: 0px;
  }
  #root .solution > .main-section .streaming > .title-section {
    padding-top: 0px;
  }
  #root .solution > .main-section .viewer > .title-section {
    padding-top: 0px;
  }
  #root .solution > .main-section .control > .title-section {
    padding-top: 0px;
  }
  #root .solution > .main-section .pilot > .title-section {
    padding-top: 0px;
  }
  #root .solution > .main-section .circle {
    width: 364px;
    height: 364px;
    top: 440px;
    right: 175px;
  }
  #root .solution > .main-section .viewercircle {
    width: 418px;
    height: 418px;
    right: -50px;
    top: 320px;
  }
  #root .solution > .main-section .controlcircle {
    width: 381px;
    height: 381px;
    right: -40px;
    top: 430px;
  }
  #root .solution > .main-section .pilotbasic {
    width: 570px;
    height: 250px;
    top: 370px;
    right: 60px;
  }
  #root .solution > .main-section > .content-section > .content {
    grid-template-columns: repeat(2, 344px);
  }
  #root .solution > .main-section > .content-section > .content > .box {
    padding: 27px;
    width: 344px;
    height: 392px;
  }
  #root .solution > .main-section > .content-section > .content > .box .point {
    width: 88px;
    height: 27px;
    background-color: var(--blue600);
    font-size: 14px;
  }
  #root .solution > .main-section > .content-section > .content > .box .title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  #root .solution > .main-section > .content-section > .content > .box .sub {
    margin-bottom: 3px;
    font-size: 13px;
  }
  #root .solution > .main-section > .content-section > .content > .box .sub .dot {
    margin-top: 10px;
  }
  #root .solution > .main-section > .content-section > .content > .box .subtitle-wrapper {
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .solution > .main-section > .content-section > .content > .box .subtitle-wrapper .left {
    margin-bottom: 4px;
    margin-top: 0px;
    margin-right: 0px;
    font-size: 14px;
  }
  #root .solution > .main-section > .content-section > .content > .box .subtitle-wrapper .right > .sub > .dot {
    margin-top: 10px;
  }
  #root .solution > .main-section > .content-section > .content .eng {
    height: 500px;
  }
  #root .solution > .main-section > .content-section > .content > .img-box {
    width: 344px;
    height: 392px;
  }
  #root .solution > .main-section > .content-section > .content > .img-box > img {
    width: 344px;
    height: 392px;
  }
  #root .solution > .main-section > .content-section > .content .eng {
    height: 500px;
  }
  #root .solution > .main-section > .content-section > .content .eng > img {
    height: 500px;
  }
}
@media screen and (max-width: 767px) {
  #root .solution .mb {
    display: block;
  }
  #root .solution .tablet {
    display: none;
  }
  #root .solution > .main-section {
    width: 328px;
  }
  #root .solution > .main-section > .title-wrapper > .title-section > .title {
    font-size: 30px;
  }
  #root .solution > .main-section .gradation {
    right: -150px;
  }
  #root .solution > .main-section > .pilot-box-wrapper > .title {
    font-size: 20px;
    margin-bottom: 36px;
  }
  #root .solution > .main-section > .pilot-box-wrapper > .box {
    width: 328px;
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    row-gap: 15px;
  }
  #root .solution > .main-section > .pilot-box-wrapper > .box > .pilot-box {
    width: 160px;
    height: 49px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(244, 245, 245, 0.140625)));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(244, 245, 245, 0.140625) 100%);
    -webkit-box-shadow: 0px 3.4477px 23.272px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 3.4477px 23.272px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(43.0962px);
            backdrop-filter: blur(43.0962px);
    border-radius: 8.61925px;
  }
  #root .solution > .main-section .circle {
    width: 200px;
    height: 200px;
    top: 400px;
    right: 65px;
  }
  #root .solution > .main-section .viewercircle {
    width: 210px;
    height: 210px;
    right: -30px;
    top: 330px;
  }
  #root .solution > .main-section .controlcircle {
    width: 232px;
    height: 232px;
    right: 20px;
    top: 380px;
  }
  #root .solution > .main-section .pilotbasic {
    width: 328px;
    height: 143px;
    top: 330px;
    right: 10px;
  }
  #root .solution > .main-section > .content-section > .content {
    grid-template-columns: repeat(1, 328px);
  }
  #root .solution > .main-section > .content-section > .content > .box {
    padding: 37px 20px;
    width: 328px;
    height: 404px;
  }
  #root .solution > .main-section > .content-section > .content > .box .point-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .solution > .main-section > .content-section > .content > .box .title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 19px;
  }
  #root .solution > .main-section > .content-section > .content > .dot-box .sub .dot {
    width: 10px;
  }
  #root .solution > .main-section > .content-section > .content > .eng {
    height: 600px;
  }
  #root .solution > .main-section > .content-section > .content > .img-box {
    width: 328px;
    height: 328px;
  }
  #root .solution > .main-section > .content-section > .content > .img-box > img {
    width: 328px;
    height: 328px;
  }
}