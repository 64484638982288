#root .top-nav-section {
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  padding-bottom: 13px;
  grid-template-columns: repeat(4, 92px);
  -webkit-column-gap: 40px;
     -moz-column-gap: 40px;
          column-gap: 40px;
  width: 1068px;
  margin: 0 auto;
  z-index: 10;
}
#root .top-nav-section .top-nav {
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .top-nav-section > .now-nav {
  font-weight: 700;
  color: var(--blue600);
}
#root .top-nav-section .top-nav-line {
  width: 92px;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  background-color: var(--blue600);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .line-section {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 1px;
  background-color: var(--gray500);
}
#root .company-nav-section {
  grid-template-columns: repeat(3, 120px);
}
#root .company-nav-section .top-nav-line {
  width: 120px;
}
#root .company-nav-section > .active-line-1 {
  left: 162px;
}
#root .company-nav-section > .active-line-2 {
  left: 320px;
}
#root .award-nav-section {
  grid-template-columns: repeat(3, 92px);
}
#root .award-nav-section .top-nav-line {
  width: 92px;
}
#root .award-nav-section > .active-line-1 {
  left: 132px;
}
#root .award-nav-section > .active-line-2 {
  left: 262px;
}
#root .business-eng .top-nav-line {
  width: 240px;
}
#root .solution-nav-section {
  grid-template-columns: repeat(3, 142px);
}
#root .solution-nav-section .top-nav-line {
  left: 24px;
  width: 92px;
}
#root .solution-nav-section > .active-line-1 {
  width: 142px;
  left: 181px;
}
#root .solution-nav-section > .active-line-2 {
  width: 92px;
  left: 388px;
}
#root .dron-nav-section {
  grid-template-columns: repeat(6, 120px);
}
#root .dron-nav-section .top-nav-line {
  width: 60px;
  left: 30px;
}
#root .dron-nav-section > .active-line-1 {
  width: 120px;
  left: 158px;
}
#root .dron-nav-section > .active-line-2 {
  width: 60px;
  left: 350px;
}
#root .dron-nav-section > .active-line-3 {
  left: 490px;
  width: 100px;
}
#root .dron-nav-section > .active-line-4 {
  width: 140px;
  left: 630px;
}
#root .dron-nav-section > .active-line-5 {
  width: 120px;
  left: 800px;
}
#root .business-nav-section {
  grid-template-columns: repeat(4, 102px);
}
#root .business-nav-section .top-nav-line {
  width: 102px;
}
#root .business-nav-section > .active-line-1 {
  left: 142px;
}
#root .business-nav-section > .active-line-2 {
  left: 284px;
}
#root .business-nav-section > .active-line-3 {
  left: 426px;
}
#root .drop-nav-section {
  width: 100%;
  height: 42px;
  position: relative;
  z-index: 11;
}
#root .drop-nav-section .type-box {
  width: 100%;
  max-height: 42px;
  position: absolute;
  overflow: hidden;
  color: white;
  display: grid;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  background-color: black;
  row-gap: 1px;
}
#root .drop-nav-section .type-box .type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .drop-nav-section .type-box > .button,
#root .drop-nav-section .type-box .now {
  position: relative;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid var(--gray500);
  background-color: black;
  width: 100%;
  height: 42px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 12px;
  color: white;
}
#root .drop-nav-section .type-box .now > img {
  right: 16px;
  position: absolute;
  width: 20px;
}
#root .drop-nav-section .open {
  max-height: 300px;
}

@media screen and (max-width: 1439px) {
  #root .company-nav-section {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #root .award-nav-section {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #root .business-nav-section {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #root .solution-nav-section {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #root .dron-nav-section {
    grid-template-columns: repeat(6, 120px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
  }
  #root .dron-nav-section .top-nav-line {
    width: 60px;
    left: 30px;
  }
  #root .dron-nav-section > .active-line-1 {
    width: 120px;
    left: 132px;
  }
  #root .dron-nav-section > .active-line-2 {
    width: 60px;
    left: 300px;
  }
  #root .dron-nav-section > .active-line-3 {
    left: 417px;
    width: 90px;
  }
  #root .dron-nav-section > .active-line-4 {
    width: 140px;
    left: 530px;
  }
  #root .dron-nav-section > .active-line-5 {
    width: 100px;
    left: 685px;
  }
}