#root .CompanyVideo {
  width: 1136px;
  margin: 0 auto;
  padding-top: 109px;
}
#root .CompanyVideo .tablet {
  display: none;
}
#root .CompanyVideo > .main-section {
  padding-bottom: 427px;
  display: grid;
  grid-template-columns: repeat(3, 354px);
  -webkit-column-gap: 36px;
     -moz-column-gap: 36px;
          column-gap: 36px;
  row-gap: 35px;
}
#root .CompanyVideo > .main-section .box {
  background-color: transparent;
  width: 354px;
  height: 352px;
}
#root .CompanyVideo > .main-section .box .thumbnail {
  width: 354px;
  height: 215px;
  margin-bottom: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#root .CompanyVideo > .main-section .box .title-wrapper {
  text-align: left;
}
#root .CompanyVideo > .main-section .box .title-wrapper .title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 4px;
}
#root .CompanyVideo > .main-section .box .title-wrapper .date {
  font-weight: 500;
  font-size: 14px;
}

@media screen and (max-width: 1439px) {
  #root .CompanyVideo {
    width: 688px;
  }
  #root .CompanyVideo .tablet {
    display: block;
  }
  #root .CompanyVideo .main-section {
    -webkit-column-gap: 36px;
       -moz-column-gap: 36px;
            column-gap: 36px;
    grid-template-columns: repeat(2, 326px);
    row-gap: 0px;
  }
  #root .CompanyVideo .main-section .box {
    width: 326px;
  }
  #root .CompanyVideo .main-section .box .thumbnail {
    margin-bottom: 10px;
    width: 326px;
  }
  #root .CompanyVideo .main-section .box .title-wrapper .title {
    height: 50px;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 767px) {
  #root .CompanyVideo {
    padding-top: 33px;
    width: 328px;
  }
  #root .CompanyVideo .main-section {
    grid-template-columns: repeat(1, 328px);
  }
  #root .CompanyVideo .main-section .box {
    width: 328px;
  }
  #root .CompanyVideo .main-section .box .thumbnail {
    width: 328px;
  }
}