#root .ContactU {
  padding-bottom: 330px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 64px;
}
#root .ContactU .mb {
  display: none;
}
#root .ContactU .adress-section {
  text-align: center;
  z-index: 10;
}
#root .ContactU .adress-section > .title {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 20px;
}
#root .ContactU .adress-section > .map {
  width: 1105px;
  height: 381px;
  background-color: white;
  margin-bottom: 57px;
}
#root .ContactU .adress-section > .map .map {
  height: 100%;
}
#root .ContactU .adress-section > .subtitle {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 5px;
}
#root .ContactU .adress-section > .sub {
  font-weight: 700;
  font-size: 14px;
  line-height: 180%;
}
#root .ContactU .communication-section {
  display: grid;
  grid-template-columns: repeat(3, 141px);
  -webkit-column-gap: 60px;
     -moz-column-gap: 60px;
          column-gap: 60px;
}
#root .ContactU .communication-section > .box {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
}
#root .ContactU .communication-section > .box > .text > span {
  font-size: 18px;
}
#root .ContactU .line {
  padding: 40px 0px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .ContactU > .title-section {
    padding-bottom: 73px;
    width: 688px;
  }
  #root .ContactU > .title-section .left .title {
    font-size: 50px;
  }
  #root .ContactU > .title-section > .right > .gif > video {
    height: 416px;
    top: -200px;
    right: 0px;
  }
  #root .ContactU .adress-section {
    width: 688px;
  }
  #root .ContactU .adress-section > .map {
    width: 688px;
    height: 381px;
    background-color: white;
    margin-bottom: 57px;
  }
  #root .ContactU .adress-section > .map .map {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .ContactU > .title-section {
    padding-bottom: 73px;
    width: 688px;
  }
  .ContactU > .title-section .left .title {
    font-size: 50px;
  }
  .ContactU > .title-section > .right > .gif > video {
    height: 416px;
    top: -200px;
    right: 0px;
  }
  .ContactU .adress-section {
    width: 688px;
  }
  .ContactU .adress-section > .map {
    width: 688px;
    height: 381px;
    background-color: white;
    margin-bottom: 57px;
  }
  .ContactU .adress-section > .map .map {
    width: 100%;
    height: 100%;
  }
  #root .ContactU {
    padding-top: 255px;
  }
  #root .ContactU .mb {
    display: block;
  }
  #root .ContactU > .title-section {
    padding-bottom: 73px;
    width: 328px;
  }
  #root .ContactU > .title-section .left .title {
    font-size: 31px;
  }
  #root .ContactU > .title-section > .right > .gif > video {
    height: 195px;
    top: -150px;
    right: 0px;
  }
  #root .ContactU .adress-section {
    width: 100%;
  }
  #root .ContactU .adress-section > .map {
    width: 100%;
    height: 261px;
    background-color: white;
    margin-bottom: 34px;
  }
  #root .ContactU .adress-section > .map .map {
    width: 100%;
    height: 100%;
  }
  #root .ContactU .adress-section > .subtitle {
    font-size: 20px;
  }
  #root .ContactU .adress-section > .sub {
    font-size: 13px;
    line-height: 150%;
  }
  #root .ContactU .communication-section {
    grid-template-columns: repeat(1, 141px);
    row-gap: 22px;
  }
  #root .ContactU .communication-section > .box {
    font-size: 18px;
    line-height: 130%;
  }
  #root .ContactU .communication-section > .box > .text > span {
    font-size: 14px;
  }
}