#root .tablet {
  display: none;
}
#root .mb {
  display: none;
}
#root .Main .main-section {
  padding-bottom: 470px;
  position: relative;
  padding-top: 129px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .Main .main-section .title-section {
  z-index: 100;
  width: 1340px;
  margin: 0 auto;
}
#root .Main .main-section .title-section > .title {
  font-weight: 700;
  font-size: 53px;
  line-height: 130%;
  margin-bottom: 8px;
}
#root .Main .main-section .title-section > .sub {
  font-weight: 400;
  font-size: 24px;
}
#root .Main .main-section .title-section .title-btn {
  margin-bottom: 562px;
  margin-top: 223px;
}
#root .Main .main-section .title-section .title-btn .detail-nav {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  width: 171px;
  background-color: transparent;
}
#root .Main .main-section .video-section > video {
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  top: -50px;
  position: absolute;
}
#root .Main .main-section .gif-text-section {
  position: relative;
  margin-bottom: 291px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 153px;
}
#root .Main .main-section .gif-text-section .bubble1 {
  top: -200px;
  left: -500px;
  position: absolute;
  width: 724px;
  height: 724px;
  border-radius: 50%;
  background: linear-gradient(150.87deg, rgba(154, 174, 234, 0.1) 29.9%, rgba(0, 120, 255, 0) 81.14%);
  -webkit-filter: blur(44.5px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: blur(44.5px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
#root .Main .main-section .gif-text-section .bubble2 {
  right: -550px;
  bottom: 0;
  position: absolute;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background: linear-gradient(150.87deg, rgba(154, 174, 234, 0.2) 6.92%, rgba(0, 120, 255, 0.2) 93.78%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(44.5px);
          filter: blur(44.5px);
}
#root .Main .main-section .gif-text-section .gif-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
#root .Main .main-section .gif-text-section .gif-section .gif {
  height: 224px;
  margin-bottom: 36px;
}
#root .Main .main-section .gif-text-section .gif-section > .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 158%;
  margin-bottom: 20px;
}
#root .Main .main-section .gif-text-section .gif-section > .sub {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}
#root .Main .main-section .business-section {
  margin: 0 auto;
  margin-bottom: 237px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 90px;
     -moz-column-gap: 90px;
          column-gap: 90px;
}
#root .Main .main-section .business-section .title-wrapper {
  width: 440px;
}
#root .Main .main-section .business-section .title-wrapper .title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 34px;
}
#root .Main .main-section .business-section .title-wrapper .sub {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}
#root .Main .main-section .business-section .box-wrapper {
  -webkit-column-gap: 40px;
     -moz-column-gap: 40px;
          column-gap: 40px;
  row-gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 293px);
}
#root .Main .main-section .business-section .box-wrapper > .box {
  position: relative;
  padding: 24px;
  width: 293px;
  height: 293px;
  border-bottom: unset;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px white;
          box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px white;
}
#root .Main .main-section .business-section .box-wrapper > .box > .img {
  bottom: 24px;
  right: 24px;
  position: absolute;
  width: 102px;
}
#root .Main .main-section .business-section .box-wrapper > .box > .title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 8px;
}
#root .Main .main-section .business-section .box-wrapper > .box > .sub {
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
}
#root .Main .main-section .business-section .box-wrapper > .box .detail-nav {
  font-weight: 700;
  font-size: 15px;
  position: absolute;
  bottom: 24px;
  background-color: transparent;
  width: 110px;
  text-align: left;
}
#root .Main .main-section .business-section .box-wrapper .magin {
  margin-top: 40px;
}
#root .Main .main-section .solution-section {
  position: relative;
  margin-bottom: 333px;
  width: 1104px;
}
#root .Main .main-section .solution-section .bubble3 {
  top: -100px;
  left: -50px;
  position: absolute;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  background: linear-gradient(150.87deg, #1e3579 6.92%, #0078ff 93.78%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(11.5px);
          filter: blur(11.5px);
}
#root .Main .main-section .solution-section .bubble4 {
  position: absolute;
  right: -300px;
  width: 376px;
  height: 376px;
  border-radius: 50%;
  background: linear-gradient(150.87deg, #9aaeea 6.92%, #0078ff 93.78%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(44.5px);
          filter: blur(44.5px);
}
#root .Main .main-section .solution-section .bubble5 {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  position: absolute;
  left: -300px;
  bottom: -500px;
  background: linear-gradient(150.87deg, rgba(154, 174, 234, 0.5) 6.92%, rgba(0, 120, 255, 0.5) 93.78%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(44.5px);
          filter: blur(44.5px);
}
#root .Main .main-section .solution-section .title-wrapper {
  position: relative;
  margin-bottom: 81px;
}
#root .Main .main-section .solution-section .title-wrapper .title {
  z-index: 100;
  font-weight: 700;
  margin-bottom: 4px;
  font-size: 34px;
  line-height: 130%;
}
#root .Main .main-section .solution-section .title-wrapper .sub {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}
#root .Main .main-section .solution-section > .box-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 40px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box {
  position: relative;
  padding: 38px 45px;
  width: 1104px;
  height: 267px;
  border-bottom: unset;
  border-radius: 26px;
  -webkit-box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px white;
          box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px white;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(244, 245, 245, 0.140625)));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(244, 245, 245, 0.140625) 100%);
}
#root .Main .main-section .solution-section > .box-wrapper > .box > .title {
  font-weight: 700;
  font-size: 34px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box > .sub {
  font-weight: 700;
  font-size: 18px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper {
  margin-top: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper > .img {
  top: 59px;
  right: 78px;
  position: absolute;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper > .img > img {
  width: 149px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper > .icon {
  color: #1963e8;
  width: 161px;
  height: 50px;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  border-bottom: unset;
  -webkit-box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px #1963e8;
          box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px #1963e8;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper > .icon1 {
  width: 212px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper {
  margin-top: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 800px;
  gap: 16px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .img {
  top: 59px;
  right: 78px;
  position: absolute;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .img > img {
  width: 169px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .icon {
  color: #1963e8;
  width: 158px;
  height: 50px;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  border-bottom: unset;
  -webkit-box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px #1963e8;
          box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px #1963e8;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .icon1 {
  width: 135px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .icon2 {
  width: 135px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .icon4 {
  width: 215px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .icon4 {
  width: 231px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .icon3 {
  width: 129px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .icon6 {
  width: 125px;
}
#root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .icon5 {
  width: 216px;
}
#root .Main .main-section .solution-section > .box-wrapper > .bottom {
  height: 303px;
}
#root .Main .main-section .partner-section {
  margin-bottom: 212px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
#root .Main .main-section .partner-section .title-wrapper {
  margin-bottom: 50px;
}
#root .Main .main-section .partner-section .title-wrapper .title {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 34px;
}
#root .Main .main-section .partner-section .title-wrapper .sub {
  font-weight: 700;
  font-size: 18px;
}
#root .Main .main-section .partner-section .line {
  margin-bottom: 50px;
}
#root .Main .main-section .partner-section .line2 {
  margin-top: 50px;
}
#root .Main .main-section .partner-section > .box-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 239px);
  -webkit-column-gap: 70px;
     -moz-column-gap: 70px;
          column-gap: 70px;
  row-gap: 39px;
}
#root .Main .main-section .partner-section > .box-wrapper > .box {
  width: 239px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .Main .main-section .partner-section > .box-wrapper > .box > img {
  width: 125px;
  height: 40px;
}
#root .Main .main-section .service-section {
  z-index: 10;
}
#root .Main .main-section .service-section .title-wrapper {
  text-align: center;
  margin-bottom: 50px;
}
#root .Main .main-section .service-section .title-wrapper .title {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 34px;
}
#root .Main .main-section .service-section .title-wrapper .sub {
  font-weight: 700;
  font-size: 18px;
}
#root .Main .main-section .service-section .box-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 43px;
}
#root .Main .main-section .service-section .box-wrapper .box {
  -webkit-box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px white;
          box-shadow: inset 0 -5px 5px 1px black, inset 0 1px 1px 1px white;
  position: relative;
  padding: 53px;
  width: 1102px;
  height: 224px;
  border-radius: 21px;
  background: rgba(0, 0, 0, 0.23);
  -webkit-backdrop-filter: blur(41.491px);
          backdrop-filter: blur(41.491px);
}
#root .Main .main-section .service-section .box-wrapper .box .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  margin-bottom: 27px;
}
#root .Main .main-section .service-section .box-wrapper .box .button {
  width: 230px;
  height: 41px;
  background-color: white;
  font-weight: 700;
  font-size: 13.2771px;
  color: black;
  border-radius: 8px;
}
#root .Main .main-section .service-section .box-wrapper .box > .img > img {
  right: 73px;
  top: 72px;
  width: 270px;
  position: absolute;
}
#root .Main .main-section .news-section {
  margin-bottom: 400px;
  position: relative;
}
#root .Main .main-section .news-section .title-wrapper {
  text-align: center;
  margin-bottom: 50px;
}
#root .Main .main-section .news-section .title-wrapper .title {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 34px;
}
#root .Main .main-section .news-section .title-wrapper .sub {
  font-weight: 700;
  font-size: 18px;
}
#root .Main .main-section .news-section .news-wrapper {
  width: 1101px;
  overflow: hidden;
}
#root .Main .main-section .news-section .news-wrapper .news-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .Main .main-section .news-section .news-wrapper .news-container .news {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 346px;
  height: 441px;
  background-color: transparent;
}
#root .Main .main-section .news-section .news-wrapper .news-container .news .bottom {
  text-align: left;
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 140px;
  background-color: rgba(25, 99, 232, 0.8);
}
#root .Main .main-section .news-section .news-wrapper .news-container .news .bottom .date {
  font-weight: 700;
  font-size: 10.9275px;
  margin-bottom: 4px;
}
#root .Main .main-section .news-section .news-wrapper .news-container .news .bottom .title {
  font-weight: 700;
  font-size: 24.769px;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .Main .main-section .news-section .news-wrapper .news-container .news .bottom .text {
  font-weight: 700;
  font-size: 10.9275px;
  line-height: 150%;
}
#root .Main .main-section .news-section .scrollbar {
  display: none;
}
#root .Main .main-section .news-section .slider-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 100;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .Main .main-section .news-section .slider-nav .prev-btn {
  position: absolute;
  left: -100px;
  top: 300px;
}
#root .Main .main-section .news-section .slider-nav .next-btn {
  position: absolute;
  right: -100px;
  top: 300px;
}
#root .Main .main-section .news-section .slider-nav button {
  border: none;
  background-color: transparent;
  color: #555;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}
#root .Main .main-section .news-section .slider-nav button:last-child {
  margin-right: 0;
}
#root .Main .main-section .bottom-img {
  width: 1440px;
  position: absolute;
  right: 0;
  bottom: 0;
}
#root .Main .main-section .bottom-img > img {
  height: 1080px;
}

@media screen and (max-width: 1439px) {
  #root .tablet {
    display: block;
  }
  #root .Main .main-section {
    padding-bottom: 186px;
    padding-top: 119px;
  }
  #root .Main .main-section .title-section {
    z-index: 100;
    width: 688px;
    margin: 0 auto;
  }
  #root .Main .main-section .title-section > .title {
    font-size: 40px;
    line-height: 140%;
    margin-bottom: 15px;
  }
  #root .Main .main-section .title-section > .sub {
    font-size: 20px;
  }
  #root .Main .main-section .title-section .title-btn {
    margin-top: 65px;
  }
  #root .Main .main-section .video-section > video {
    width: 925px;
    top: 350px;
  }
  #root .Main .main-section .gif-text-section {
    margin-bottom: 347px;
    row-gap: 116px;
  }
  #root .Main .main-section .gif-text-section .bubble1 {
    top: -100px;
    left: -150px;
    width: 320px;
    height: 320px;
  }
  #root .Main .main-section .gif-text-section .bubble2 {
    right: -300px;
    bottom: -170px;
    width: 320px;
    height: 320px;
  }
  #root .Main .main-section .gif-text-section .gif-section .gif {
    width: 132px;
    height: 170px;
  }
  #root .Main .main-section .gif-text-section .gif-section > .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 158%;
  }
  #root .Main .main-section .business-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-column-gap: 90px;
       -moz-column-gap: 90px;
            column-gap: 90px;
  }
  #root .Main .main-section .business-section .title-wrapper {
    text-align: center;
    margin-bottom: 70px;
    width: 100%;
  }
  #root .Main .main-section .solution-section {
    margin-bottom: 249px;
    width: 688px;
  }
  #root .Main .main-section .solution-section .bubble3 {
    top: -50px;
    left: 100px;
    width: 128px;
    height: 128px;
  }
  #root .Main .main-section .solution-section .bubble4 {
    top: 100px;
    right: -100px;
    width: 218px;
    height: 218px;
  }
  #root .Main .main-section .solution-section .bubble5 {
    width: 239px;
    height: 239px;
    left: -100px;
    bottom: -500px;
  }
  #root .Main .main-section .solution-section .title-wrapper {
    text-align: center;
  }
  #root .Main .main-section .solution-section > .box-wrapper {
    row-gap: 48px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 45px 59px;
    width: 688px;
    height: 427px;
    position: relative;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box > .title {
    font-weight: 700;
    font-size: 34px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box > .sub {
    font-weight: 700;
    font-size: 18px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper {
    margin-top: 216px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper > .img {
    top: 140px;
    right: 268px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper > .img > img {
    width: 149px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper {
    margin-top: 202px;
    padding-left: 100px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .img {
    top: 140px;
    right: 268px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .bottom {
    height: 476px;
  }
  #root .Main .main-section .partner-section {
    margin-bottom: 423px;
  }
  #root .Main .main-section .partner-section > .box-wrapper {
    grid-template-columns: repeat(2, 239px);
    -webkit-column-gap: 50px;
       -moz-column-gap: 50px;
            column-gap: 50px;
    row-gap: 57px;
  }
  #root .Main .main-section .service-section {
    z-index: 10;
  }
  #root .Main .main-section .service-section .title-wrapper {
    margin-bottom: 50px;
  }
  #root .Main .main-section .service-section .box-wrapper .box {
    padding: 52px 42px;
    width: 688px;
    height: 225px;
  }
  #root .Main .main-section .news-section {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0 40px;
  }
  #root .Main .main-section .news-section .news-wrapper {
    width: 871px;
    margin: 0 auto;
    overflow: scroll;
  }
  #root .Main .main-section .news-section .news-wrapper .news-container {
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
  }
  #root .Main .main-section .news-section .news-wrapper .news-container .news {
    width: 276px;
    height: 368px;
  }
  #root .Main .main-section .news-section .news-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  #root .Main .main-section .news-section .news-wrapper::-webkit-scrollbar {
    display: none;
    /* Chrome , Safari , Opera */
  }
  #root .Main .main-section .news-section .scrollbar {
    display: block;
    margin: 0 auto;
    width: 688px;
    height: 4px;
    margin-top: 30px;
    background-color: var(--gray400);
    border-radius: 4px;
    overflow: hidden;
  }
  #root .Main .main-section .news-section .scrollbar .activebar {
    height: 100%;
    width: 100px;
    background-color: #1963e8;
  }
  #root .Main .main-section .news-section .slider-nav {
    display: none;
  }
  #root .Main .main-section .bottom-img {
    width: 1170px;
    height: 805px;
  }
  #root .Main .main-section .bottom-img > img {
    height: 805px;
  }
}
@media screen and (max-width: 767px) {
  #root .mb {
    display: block;
  }
  #root .pc {
    display: none;
  }
  #root .Main .main-section {
    padding-top: 97px;
  }
  #root .Main .main-section .title-section {
    width: 328px;
  }
  #root .Main .main-section .title-section > .title {
    font-size: 24px;
    line-height: 140%;
  }
  #root .Main .main-section .title-section .title-btn {
    margin-top: 56px;
  }
  #root .Main .main-section .video-section > video {
    width: 767px;
  }
  #root .Main .main-section .gif-text-section {
    margin-bottom: 148px;
    row-gap: 98px;
  }
  #root .Main .main-section .gif-text-section .bubble1 {
    display: none;
  }
  #root .Main .main-section .gif-text-section .bubble2 {
    display: none;
  }
  #root .Main .main-section .gif-text-section .gif-section .gif {
    width: 96px;
    height: 123px;
  }
  #root .Main .main-section .gif-text-section .gif-section > .title {
    font-size: 16px;
  }
  #root .Main .main-section .gif-text-section .gif-section > .sub {
    font-size: 12px;
  }
  #root .Main .main-section .business-section {
    padding-bottom: 213px;
    width: 293px;
    margin: 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
  }
  #root .Main .main-section .business-section .title-wrapper > .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }
  #root .Main .main-section .business-section .title-wrapper > .sub {
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
  }
  #root .Main .main-section .business-section .box-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 293px);
  }
  #root .Main .main-section .news-section {
    padding: 0 16px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 210px;
  }
  #root .Main .main-section .news-section .news-wrapper {
    width: 100%;
  }
  #root .Main .main-section .news-section .scrollbar {
    width: 100%;
  }
  #root .Main .main-section .solution-section {
    margin-bottom: 249px;
    width: 328px;
  }
  #root .Main .main-section .solution-section .bubble3 {
    top: -20px;
    left: 0px;
    width: 74px;
    height: 74px;
  }
  #root .Main .main-section .solution-section .bubble4 {
    top: 140px;
    right: -100px;
    width: 189px;
    height: 189px;
  }
  #root .Main .main-section .solution-section .bubble5 {
    display: none;
  }
  #root .Main .main-section .solution-section .title-wrapper {
    text-align: center;
  }
  #root .Main .main-section .solution-section .title-wrapper .title {
    font-size: 24px;
    line-height: 150%;
  }
  #root .Main .main-section .solution-section .title-wrapper .sub {
    font-size: 12px;
    line-height: 150%;
  }
  #root .Main .main-section .solution-section > .box-wrapper {
    row-gap: 48px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box {
    width: 328px;
    text-align: center;
    height: 551px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box > .title {
    font-size: 26px;
    margin-bottom: 4px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box > .sub {
    width: 160px;
    font-size: 14px;
    line-height: 150%;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 167px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper .icon {
    width: 283px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper > .img {
    top: 150px;
    right: 110px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .top-wrapper > .img > img {
    width: 105px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper {
    padding-left: 0;
    width: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 160px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper .icon {
    width: 283px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .img {
    top: 150px;
    right: 110px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .box .bottom-wrapper > .img > img {
    width: 105px;
  }
  #root .Main .main-section .solution-section > .box-wrapper > .bottom {
    height: 791px;
  }
  #root .Main .main-section .partner-section {
    margin-bottom: 423px;
  }
  #root .Main .main-section .partner-section > .box-wrapper {
    grid-template-columns: repeat(2, 160px);
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
    row-gap: 30px;
  }
  #root .Main .main-section .partner-section > .box-wrapper .box {
    width: 160px;
  }
  #root .Main .main-section .partner-section .title-wrapper .title {
    font-size: 24px;
  }
  #root .Main .main-section .partner-section .title-wrapper .sub {
    font-size: 12px;
  }
  #root .Main .main-section .service-section {
    z-index: 10;
  }
  #root .Main .main-section .service-section .title-wrapper {
    margin-bottom: 50px;
  }
  #root .Main .main-section .service-section .title-wrapper > .sub {
    white-space: pre-line;
  }
  #root .Main .main-section .service-section .box-wrapper .box {
    position: relative;
    width: 328px;
    height: 376px;
  }
  #root .Main .main-section .service-section .box-wrapper .box > .title {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }
  #root .Main .main-section .service-section .box-wrapper .box .img > img {
    width: 215px;
    top: 180px;
  }
  #root .Main .main-section .service-section .box-wrapper .box .button {
    bottom: 40px;
    position: absolute;
  }
  #root .Main .main-section .bottom-img {
    width: 100%;
  }
  #root .Main .main-section .bottom-img > img {
    height: 100%;
  }
}