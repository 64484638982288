.file-uploader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.file-uploader > .title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px;
}
.file-uploader input[type=text] {
  width: 357px;
  height: 50px;
  padding: 8px;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #949494;
  color: #949494;
}
.file-uploader label {
  margin-left: 11px;
  width: 126px;
  height: 50px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 8px 12px;
  background-color: #00266a;
  color: white;
  cursor: pointer;
  font-weight: bold;
}
.file-uploader input[type=file] {
  display: none;
}
.file-uploader button[type=submit] {
  padding: 8px 16px;
  background-color: #242627;
  color: white;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .file-uploader input[type=text] {
    width: 183px;
    height: 50px;
  }
}